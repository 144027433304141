import React, { useState } from 'react';
import { flatto } from '@alizeait/flatto';
import { unflatto } from '@alizeait/unflatto';
import { Translator as TranslatorBase } from '@volvo-cars/react-shared-translations';
import deepmerge from 'deepmerge';

const DICTIONARIES_DATA_ATTRIBUTE = 'data-vcc-dictionaries';
type TranslationsCache = Record<string, Record<string, Record<string, string>>>;

// Translator extension that supports streaming
class ClientTranslator extends TranslatorBase {
  constructor() {
    super();
    super.cache = this._getTranslations();
  }

  translate(
    key: string,
    options?: { skipEmit?: boolean | undefined } | undefined,
  ) {
    if (document.readyState !== 'complete') {
      super.cache = this._getTranslations();
    }

    return super.translate(key, options);
  }

  private _getTranslations(): TranslationsCache {
    if (typeof window === 'undefined') return {};

    const elements = document.querySelectorAll(
      `[${DICTIONARIES_DATA_ATTRIBUTE}]`,
    );

    if (!elements) return super.cache || {};

    try {
      const objects = Array.from(elements).map((element) => {
        try {
          return JSON.parse(element.innerHTML);
        } catch (error) {
          console.log(error);
          return {};
        }
      });

      if (!objects.length) {
        return super.cache || {};
      }

      return deepmerge.all([
        ...objects,
        super.cache || {},
      ]) as TranslationsCache;
    } catch (error) {
      console.error(error);

      return super.cache || {};
    }
  }
}

// Only needed for the client translator
export const Translator =
  typeof window === 'undefined' ? TranslatorBase : ClientTranslator;

export const useSharedTranslationsFlusher = () => {
  const [flushedTranslations] = useState(() => {
    return new Set();
  });

  return function flushTranslations(translator: TranslatorBase) {
    const cache = translator.cache;

    if (!cache || !Object.keys(cache).length) return null;

    const flattenedCache = flatto<TranslationsCache, Record<string, string>>(
      cache,
    );

    const toFlushEntries = Object.entries(flattenedCache).filter(([key]) => {
      return !flushedTranslations.has(key);
    });

    if (!toFlushEntries.length) {
      return null;
    }

    const toBeFlushed =
      unflatto<Record<string, string>, TranslationsCache>(
        Object.fromEntries(toFlushEntries),
      ) || {};

    Object.keys(flattenedCache).forEach(
      flushedTranslations.add,
      flushedTranslations,
    );

    return <TranslatorCache cache={toBeFlushed} />;
  };
};

interface TranslatorCacheProps {
  cache: TranslationsCache;
}

const key = { [DICTIONARIES_DATA_ATTRIBUTE]: true };

export const TranslatorCache: React.FC<TranslatorCacheProps> = ({ cache }) => {
  try {
    return (
      <script
        type="application/json"
        {...key}
        dangerouslySetInnerHTML={{ __html: JSON.stringify(cache) }}
      />
    );
  } catch (error) {
    console.log(error);
    return null;
  }
};
