'use client';
import React, { useEffect, useState } from 'react';
import {
  Translator,
  useSharedTranslationsFlusher,
} from '@vcc-package/react-shared-translations';
import {
  getVariablesFromPublicConfig,
  registerBrowserInstrumentation,
} from '@vcc-package/opentelemetry/browser';
import { useFlushStyles } from '@vcc-www/hooks/vcc-ui/useFlushStyles';
import { useStyleRenderer } from '@vcc-www/hooks/vcc-ui/useStyleRenderer';
import { urlFromString, VolvoCarsUrlProvider } from '@vcc-www/volvo-cars-url';
import { getMarketSite } from '@volvo-cars/market-sites';
import { TranslatorProvider } from '@volvo-cars/react-shared-translations';
import { reportWebVitals } from '@volvo-cars/tracking/webVitals';
import { PublicEnv, getPublicEnv } from 'src/public-env';
import { useCallOnce } from '@vcc-www/hooks/useCallOnce';
import {
  usePathname,
  useSearchParams,
  useServerInsertedHTML,
} from 'next/navigation';
import { useReportWebVitals } from 'next/web-vitals';
import { LinkComponent } from 'src/components/LinkComponent';
import { ConfigProvider, StyleProvider, ThemePicker } from 'vcc-ui';
import { createRenderer } from '@vcc-www/global-style-provider';

const getStyleRenderer = () => {
  return createRenderer(undefined, '#__next');
};

registerBrowserInstrumentation({
  ...getVariablesFromPublicConfig(getPublicEnv()),
});

// RootLayoutProvider that is shared across all routes
export const RootLayoutProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [routeChangeHandler, setRouteChangeHandler] = useState<() => void>();

  // Update VolvoCarsUrl when the route changes. VolvoCarsUrl by default listens
  // to click events only which is too early some times
  useEffect(() => {
    if (!routeChangeHandler) return;
    routeChangeHandler();
  }, [pathname, searchParams, routeChangeHandler]);

  const url = urlFromString(pathname || '');
  const { locale, languageDirection } = getMarketSite(url.siteSlug);

  const styleRenderer = useStyleRenderer(getStyleRenderer);
  const flushStyles = useFlushStyles(getStyleRenderer);
  const flushTranslations = useSharedTranslationsFlusher();

  const [translator] = useState(() => {
    return new Translator();
  });

  const renderPublicEnv = useCallOnce(() => <PublicEnv />);

  useServerInsertedHTML(() => {
    return (
      <>
        {flushStyles(styleRenderer)}
        {flushTranslations(translator)}
        {renderPublicEnv()}
      </>
    );
  });

  useReportWebVitals((metrics) => {
    window.dataLayer = window.dataLayer || [];

    const {
      marketLanguage,
      currencyCode,
      saleType,
      salesChannel,
      featureList,
    } = (window.dataLayer.find(
      (event) => event && typeof event === 'object' && 'pageType' in event,
    ) || {}) as Record<string, unknown>;

    const ga4PageViewEvent = window.dataLayer.find(
      (event: any) =>
        event && typeof event === 'object' && event.event === 'page_view',
    ) as Record<string, unknown>;

    const { page_type, page_name, country_code, sub_page_name } =
      ga4PageViewEvent || {};

    if (metrics.label === 'web-vital' && ga4PageViewEvent) {
      reportWebVitals({
        metrics,
        additionalEventData: {
          pageName: page_name,
          pageType: page_type,
          marketLanguage,
          currencyCode,
          saleType,
          salesChannel,
          featureList,
          countryCode: country_code,
          subpageName: sub_page_name,
        },
        options: { mode: 'ga4' },
      });
    }
  });

  return (
    <ConfigProvider config={{ locale, linkComponent: LinkComponent }}>
      <StyleProvider renderer={styleRenderer}>
        <ThemePicker direction={languageDirection}>
          <VolvoCarsUrlProvider
            url={urlFromString(pathname || '')}
            setRouteChangeHandler={setRouteChangeHandler}
          >
            <TranslatorProvider locale={locale} translator={translator}>
              {children}
            </TranslatorProvider>
          </VolvoCarsUrlProvider>
        </ThemePicker>
      </StyleProvider>
    </ConfigProvider>
  );
};
